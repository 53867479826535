import styled from "styled-components";

export const Wrapper = styled.div`
  h2 {
    font-weight: 600;
    font-size: 36px;
    color: var(--main);
  }
  p {
    color: var(--main);
    margin-bottom: 18px;
    margin-top: 12px;
    font-weight: 500;
  }
  input {
    width: 100%;
    margin: 0 !important;
    padding: 0;
    font-size: 16px;
  }
  .bottom {
    font-size: 14px;
    display: block;
    text-align: center;
    margin-top: 15px;
  }
  button {
    margin-top: 12px;
  }
  .error {
    color: #ff0033 !important;
  }
  .small {
    font-size: 15px;
    margin: 18px 0;
    padding-right: 10%;
    line-height: 1.5;
    span {
      color: var(--orange);
    }
  }
  .otp {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 20%;
    input {
      padding: 12px;
      background-color: var(--border-color);
      color: var(--main);
      font-weight: 600;
      font-size: 40px;
      border-radius: 12px;
      width: 100% !important;
    }
  }
  div {
    justify-content: space-between;
  }
`;

export const Phone = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--icon);
  padding: 14px;
  border-radius: 12px;
  background: var(--toggle);
  color: var(--main);
  span {
    width: 21%;
    font-weight: 500;
    display: flex;
    align-items: center;
    svg {
      transform: translateY(1px);
      font-size: 22px;
      margin-right: 10px;
    }
  }
  @media (max-width: 700px) {
    span {
      width: 30%;
    }
  }
`;
