import styled, { keyframes } from "styled-components";

const anime = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.button`
  width: 50%;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 18px 20px;
  border-radius: 16px;
  text-align: center;
  padding: 16px;
  :disabled {
    cursor: not-allowed;
    background-color: var(--icon);
    color: var(--white);
  }
  i {
    margin-right: 8px;
    transform: translateY(2px);
    color: #fff;
    animation: ${anime} 1.2s linear infinite;
  }
  &.toggle {
    background-color: var(--toggle);
    color: var(--main);
  }
  &.yellow {
    background-color: var(--orange);
  }
  &.gray {
    background-color: var(--icon);
  }
  &.full {
    width: 100%;
  }
  &:disabled {
    background-color: var(--icon);
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
