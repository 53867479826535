import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { DarkModeProvider } from "./context/darkMode";
import { ProductModalProvider } from "./context/productModal";
import { LoginProvider } from "./context/loginModal";
import { BasketProvider } from "./context/basket";
import { AuthProvider } from "./context/auth";
import App from "./App";
import "./i18/i18init";

render(
  <BrowserRouter>
    <DarkModeProvider>
      <AuthProvider>
        <ProductModalProvider>
          <LoginProvider>
            <BasketProvider>
              <App />
            </BasketProvider>
          </LoginProvider>
        </ProductModalProvider>
      </AuthProvider>
    </DarkModeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
