import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: var(--body-color);
  border-radius: 18px;
  /* padding: 0px 10px; */
  h3 {
    margin-bottom: 20px;
  }
  .product-img {
    height: 175px;
  }
  @media (max-width: 800px) {
    .grid {
      width: 100%;
      overflow: auto;
      display: flex;
      padding-bottom: 10px;
      padding-left: 0;
      &::-webkit-scrollbar {
        display: none;
      }
      .product-card {
        width: 175px !important;
      }
    }
    .product-img {
      height: 170px;
    }
    .left,
    .right,
    .full {
      transform: translateY(0px) !important;
    }
  }
  @media (min-width: 2100px) {
    margin-top: 15px;
    .grid {
      grid-template-columns: repeat(5, 1fr);
    }
    .product-img {
      height: 200px;
    }
  }
`;
