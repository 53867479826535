import { lazy } from "react";

export const routes = [
  {
    path: "/",
    component: lazy(() => import("../pages/main/main")),
    exact: true,
    private: false,
    fallback: <></>,
  },
  {
    path: "/search/:search",
    component: lazy(() => import("../pages/main/main")),
    exact: true,
    private: false,
    fallback: <></>,
  },
  {
    path: "/categories",
    component: lazy(() => import("../pages/categories/categories")),
    exact: true,
    private: false,
    fallback: <></>,
  },
  {
    path: "/item/:itemId",
    component: lazy(() => import("../pages/product-detail/product-detail")),
    exact: true,
    private: false,
    fallback: <></>,
  },
  {
    path: "/category/:category/:sub",
    component: lazy(() => import("../pages/main/main")),
    exact: true,
    private: false,
    fallback: <></>,
  },
  {
    path: "/user",
    component: lazy(() => import("../pages/user/user")),
    exact: true,
    private: true,
    fallback: <></>,
  },
  {
    path: "/basket",
    component: lazy(() => import("../pages/basket/basket")),
    exact: true,
    private: false,
    fallback: <></>,
  },
  {
    path: "/orders",
    component: lazy(() => import("../pages/orders/order")),
    exact: true,
    private: true,
    fallback: <></>,
  },
  {
    path: "/order-detail/:id",
    component: lazy(() => import("../pages/order-detail/order-detail")),
    exact: true,
    private: true,
    fallback: <></>,
  },
  {
    path: "/order",
    component: lazy(() => import("../pages/order/order")),
    exact: true,
    private: true,
    fallback: <></>,
  },
  {
    path: "/favorite",
    component: lazy(() => import("../pages/favorite/lovely")),
    exact: true,
    private: true,
    fallback: <></>,
  },
  {
    path: "/cards",
    component: lazy(() => import("../pages/cards/cards")),
    exact: true,
    private: true,
    fallback: <></>,
  },
  {
    path: "",
    component: lazy(() => import("../pages/main/main")),
    exact: true,
    private: false,
    fallback: <></>,
  },
];
