import { createContext, useState } from "react";

export const ProductModalContext = createContext();

export function ProductModalProvider(props) {
  const [productModal, setProductModal] = useState(false);

  return (
    <ProductModalContext.Provider value={{ productModal, setProductModal }}>
      {props.children}
    </ProductModalContext.Provider>
  );
}
