import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { HiMinus, HiPlus } from "react-icons/hi";
import useBasket from "../../../hooks/useBasket";
import { AuthContext } from "../../../context/auth";
import { BasketContext } from "../../../context/basket";
import { LoginModalContext } from "../../../context/loginModal";
import { AddButton } from "./product.e";

function AddBasket({ product }) {
  const { basket } = useContext(BasketContext);
  const { setLoginModal } = useContext(LoginModalContext);
  const { isLogged } = useContext(AuthContext);
  const { updateBasket, basketLoading } = useBasket();
  const { t } = useTranslation();

  // Send request to the server for adding new product to the user's basket
  const addProduct = () => {
    let previousItems = basket?.items ? basket?.items : [];
    // - Add new product to top of previous products
    previousItems.unshift({
      item_id: product._id,
      count: 1,
    });
    // - Send post request to the server
    isLogged ? updateBasket(previousItems) : setLoginModal(true);
  };

  // Send request to the server for plus count exist product on the basket
  const plusProduct = () => {
    // - Find match item id and plus count
    let formatted = basket?.items?.map((el) =>
      el.item_id === product._id ? { ...el, count: el.count + 1 } : el
    );
    // - Send post request to the server
    updateBasket(formatted);
  };

  // Send request to the server for minus count exist product on the basket
  const minusProduct = () => {
    // - Find match item id and minus count
    let formatted = basket?.items?.map((el) =>
      el.item_id === product._id ? { ...el, count: el.count - 1 } : el
    );
    // - Send post request to the server
    updateBasket(formatted);
  };

  return (
    <>
      {!basket?.items?.find((item) => item.item_id === product._id) ? (
        <AddButton className="product-card">
          <button
            className={product.in_stock <= 0 ? "not_in_stock" : {}}
            disabled={product.in_stock <= 0}
            onClick={addProduct}
          >
            {product.in_stock >= 1 ? t("to_basket") : t("not_in_stock")}
          </button>
        </AddButton>
      ) : (
        <AddButton
          className="product-card"
          style={
            basketLoading
              ? { padding: "13px 25px", justifyContent: "center" }
              : {}
          }
        >
          {basketLoading ? (
            <div style={{ margin: "0 auto" }} className="small-loader" />
          ) : (
            <>
              <button
                className="left"
                onClick={minusProduct}
                aria-label="plusProduct"
              >
                <HiMinus fontSize="17px" />
              </button>
              <span>
                {basket?.items?.filter(
                  (item) => item.item_id === product._id
                )[0]?.count || 1}
              </span>
              <button
                className="right"
                aria-label="minusProduct"
                onClick={plusProduct}
              >
                <HiPlus fontSize="17px" />
              </button>
            </>
          )}
        </AddButton>
      )}
    </>
  );
}

export default AddBasket;
