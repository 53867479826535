import { createContext, useState, useContext, useEffect } from "react";
import { AuthContext } from "./auth";
export const BasketContext = createContext();

export function BasketProvider(props) {
  const [basket, setBasket] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const { isLogged } = useContext(AuthContext);

  useEffect(() => {
    if (!isLogged) {
      setTotalPrice(0);
      setBasket([]);
    }
  }, [isLogged]);

  return (
    <BasketContext.Provider
      value={{ basket, setBasket, totalPrice, setTotalPrice }}
    >
      {props.children}
    </BasketContext.Provider>
  );
}
