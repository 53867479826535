import { Wrapper } from "./button.e";

function Button({ loading, onClick, text, disabled, full, color, size, icon }) {
  return (
    <Wrapper
      className={`${color ? color : ""} ${size ? size : ""} ${
        full ? "full" : ""
      } ${icon ? `icon icon-${icon}` : ""}`}
      disabled={loading || disabled ? true : false}
      onClick={onClick}
    >
      {loading && <i className="fa-solid fa-spinner" />}
      {text}
    </Wrapper>
  );
}

export default Button;
