import { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { HiMinus, HiPlus } from "react-icons/hi";
import SimilarProducts from "../../common/similar-products/similar-products";
import { BasketContext } from "../../../context/basket";
import useBasket from "../../../hooks/useBasket";
import { AuthContext } from "../../../context/auth";
import { LoginModalContext } from "../../../context/loginModal";
import {
  AsModal,
  ModalCard,
  ModalRight,
  ModalHead,
  ModalSection,
  ModalGrid,
  AddButton,
} from "../product/product.e";

function ProductModal({ open, setClose }) {
  const [product, setProduct] = useState(
    JSON.parse(localStorage.getItem("modalProduct"))
  );
  const [count, setCount] = useState(0);
  const [isFavorite, setIsFavorite] = useState(product.is_favorite);
  const { basket } = useContext(BasketContext);
  const modalRef = useRef();
  const { t } = useTranslation();
  let itemLocal = localStorage.getItem("modalProduct");
  const { setLoginModal } = useContext(LoginModalContext);
  const { isLogged } = useContext(AuthContext);
  const { updateBasket, basketLoading } = useBasket();

  useEffect(() => {
    setProduct(JSON.parse(localStorage.getItem("modalProduct")));
  }, [itemLocal, count]);

  function handleBackClick() {
    modalRef.current.scrollIntoView({ behavior: "smooth" });
  }

  // Profile update request to Backend
  const updateUserData = (bool) => {
    axios
      .post(
        `https://beeto.in1.uz/api/item/set-favorite`,
        { is_favorite: bool, _id: product._id },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {});
  };

  // Send request to the server for adding new product to the user's basket
  const addProduct = () => {
    let previousItems = basket?.items ? basket?.items : [];
    // - Add new product to top of previous products
    previousItems.unshift({
      item_id: product._id,
      count: 1,
    });
    // - Send post request to the server
    isLogged ? updateBasket(previousItems) : setLoginModal(true);
  };

  // Send request to the server for plus count exist product on the basket
  const plusProduct = () => {
    // - Find match item id and plus count
    let formatted = basket?.items?.map((el) =>
      el.item_id === product._id ? { ...el, count: el.count + 1 } : el
    );
    // - Send post request to the server
    updateBasket(formatted);
  };

  // Send request to the server for minus count exist product on the basket
  const minusProduct = () => {
    // - Find match item id and minus count
    let formatted = basket?.items?.map((el) =>
      el.item_id === product._id ? { ...el, count: el.count - 1 } : el
    );
    // - Send post request to the server
    updateBasket(formatted);
  };

  return (
    <AsModal
      open={open}
      onClose={setClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ModalGrid ref={modalRef}>
          <ModalCard>
            <ModalHead className="img">
              <img alt={product.name} src={product.representation} />
              <div onClick={setClose} className="close">
                <i className="fa-solid fa-xmark" />
              </div>
            </ModalHead>
            <ModalSection>
              <ModalSection>
                <h5>{product.name}</h5>
                {localStorage.getItem("token") &&
                  JSON.parse(localStorage.getItem("token")) && (
                    <>
                      {isFavorite ? (
                        <i
                          onClick={() => {
                            updateUserData(false);
                            setIsFavorite(false);
                          }}
                          className="fa-solid fa-heart"
                        />
                      ) : (
                        <i
                          onClick={() => {
                            updateUserData(true);
                            setIsFavorite(true);
                          }}
                          className="fa-regular fa-heart"
                        />
                      )}
                    </>
                  )}
              </ModalSection>
            </ModalSection>
          </ModalCard>
          <ModalRight>
            {window.innerWidth <= 800 && (
              <ModalCard className="prices-container">
                <div className="prices">
                  {product.prices &&
                    product.prices.map((price, i) => {
                      return (
                        <div key={i}>
                          {price.from > 0 && <span>{price.from}x</span>}
                          <span>
                            {price.price && price.price.toLocaleString()}{" "}
                            {t("sum")}
                          </span>
                        </div>
                      );
                    })}
                </div>
                {!basket?.items?.find(
                  (item) => item.item_id === product._id
                ) ? (
                  <AddButton
                    style={{ justifyContent: "center" }}
                    className="yellow"
                    disabled={product.in_stock <= 0}
                    onClick={() => {
                      if (!isLogged) {
                        setLoginModal(true);
                        setClose(true);
                      } else {
                        addProduct();
                      }
                    }}
                  >
                    {product.in_stock >= 1 ? t("to_basket") : t("not_in_stock")}
                  </AddButton>
                ) : (
                  <AddButton className="yellow">
                    <span onClick={minusProduct}>
                      <HiMinus className="fa-solid fa-minus" />
                    </span>
                    <span>
                      {basket?.items?.filter(
                        (item) => item.item_id === product._id
                      )[0]?.count || 1}
                    </span>
                    <span
                      disabled={
                        product.in_stock <=
                        basket?.items?.filter(
                          (item) => item.item_id === product._id
                        )[0]?.count
                      }
                      onClick={plusProduct}
                    >
                      <HiPlus className="fa-solid fa-plus" />
                    </span>
                  </AddButton>
                )}
              </ModalCard>
            )}
            {window.innerWidth > 800 && (
              <ModalCard>
                <ModalHead>
                  <h3>{t("price")}</h3>
                </ModalHead>
                {product.prices &&
                  product.prices.map((price, i) => {
                    return (
                      <ModalSection className="flex" key={i}>
                        <p>
                          {price.price && price.price.toLocaleString()}{" "}
                          {t("sum")}
                        </p>
                        {price.from > 0 && (
                          <span>
                            {t("to")} {price.from} {t("count")}
                          </span>
                        )}
                      </ModalSection>
                    );
                  })}
              </ModalCard>
            )}
            {window.innerWidth <= 800 && (
              <ModalCard>
                <SimilarProducts
                  onClick={() => {
                    setCount(count + 1);
                    handleBackClick();
                  }}
                  category_id={product.category_id}
                />
              </ModalCard>
            )}
            <ModalCard>
              <ModalHead>
                <h3>{t("about_product")}</h3>
              </ModalHead>
              <ModalSection>
                <span>{t("content")}</span>
                <p>{product.compound ? product.compound : "-"}</p>
              </ModalSection>
              <ModalSection>
                <span>{t("brand")}</span>
                <p>{product.brand ? product.brand : "-"}</p>
              </ModalSection>
              <ModalSection>
                <span>{t("manufacturer")}</span>
                <p>{product.manufacturer ? product.manufacturer : "-"}</p>
              </ModalSection>
            </ModalCard>
            {window.innerWidth > 800 && (
              <>
                {!basket?.items?.find(
                  (item) => item.item_id === product._id
                ) ? (
                  <AddButton
                    style={{ justifyContent: "center" }}
                    className="yellow"
                    disabled={product.in_stock <= 0}
                    onClick={() => {
                      if (!isLogged) {
                        setLoginModal(true);
                        setClose(true);
                      } else {
                        addProduct();
                      }
                    }}
                  >
                    {product.in_stock >= 1 ? t("to_basket") : t("not_in_stock")}
                  </AddButton>
                ) : (
                  <AddButton className="yellow">
                    {basketLoading ? (
                      <div className="small-loader yellow" />
                    ) : (
                      <>
                        {" "}
                        <span onClick={minusProduct}>
                          <HiMinus className="fa-solid fa-minus" />
                        </span>
                        <span>
                          {basket?.items?.filter(
                            (item) => item.item_id === product._id
                          )[0]?.count || 1}
                        </span>
                        <span
                          onClick={plusProduct}
                          disabled={
                            product.in_stock <=
                            basket?.items?.filter(
                              (item) => item.item_id === product._id
                            )[0]?.count
                          }
                        >
                          <HiPlus className="fa-solid fa-plus" />
                        </span>
                      </>
                    )}
                  </AddButton>
                )}
              </>
            )}
          </ModalRight>
        </ModalGrid>
        {window.innerWidth > 800 && (
          <SimilarProducts
            onClick={() => {
              setCount(count + 1);
              handleBackClick();
            }}
            category_id={product.category_id}
          />
        )}
      </Box>
    </AsModal>
  );
}

export default ProductModal;
