import { AsModal, Card } from "./modal.e";
import { MdOutlineClose } from "react-icons/md";

function Modal({ width, open, setClose, children, otherClose, disable }) {
  return (
    <AsModal
      onClose={() => {
        !disable && setClose(false);
        !disable && otherClose && otherClose();
      }}
      isOpen={open}
    >
      <Card style={{ width: width }}>
        {children}{" "}
        {disable && (
          <MdOutlineClose
            fontSize={32}
            fill="#000"
            className="closer"
            onClick={() => setClose(false)}
          />
        )}
      </Card>
    </AsModal>
  );
}

export default Modal;
