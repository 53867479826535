import { Switch, BrowserRouter as Router } from "react-router-dom";
import { routes } from "./config";
import RouteWithSubRoutes from "./route-with-sub-routes";

const Routes = () => {
  return (
    <Router>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </Router>
  );
};

export default Routes;
