import styled from "styled-components";
import { Modal } from "react-tiny-modal";

export const AsModal = styled(Modal)`
  z-index: 999999999 !important;
  background: rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  .error {
    color: var(--red);
    margin: 5px 0;
    font-size: 12px;
    font-weight: 400;
  }
  .closer {
    stroke: red;
    position: absolute;
    top: 15px;
    right: 30px;
    cursor: pointer;
    box-shadow: -1px 4px 22px 7px rgb(0 0 0 / 5%);
    padding: 5px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

export const Card = styled.div`
  width: 1000px;
  padding: 36px;
  border-radius: 24px;
  background-color: var(--product-card);
  border: 0 solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 4px 36px rgba(21, 26, 36, 0.08);
`;
