import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #692ddf;
  text-align: center;
  cursor: pointer;
  position: relative;
  img {
    width: 70%;
    height: 60px;
    object-fit: cover;
    transform: translateY(3px);
  }
  div {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-55%);
    font-size: 18px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    svg {
      stroke: #fff;
    }
  }
  @media (max-width: 700px) {
    img {
      width: 90%;
      height: 50px;
    }
  }
`;
