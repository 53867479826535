import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BasketContext } from "../../../context/basket";
import "react-lazy-load-image-component/src/effects/blur.css";
import Broken from "../../../assets/images/broken.svg";
import {
  ProductPrice,
  ProductImg,
  ProductSize,
  ProductTitle,
  Wrapper,
} from "./product.e";
import AddBasket from "./add-basket";
// import cookie from "js-cookie";

const Product = React.memo(({ product, onClick }) => {
  const { basket } = useContext(BasketContext);
  // const [price, setPrice] = useState(product?.prices[0]?.price);
  const { t } = useTranslation();

  // let selectedLang = cookie.get("i18next");
  // const [lang, setLang] = useState(selectedLang);
  // useEffect(() => {
  //   setLang(cookie.get("i18next"));
  // }, [selectedLang]);

  // Change the price depending on the count

  return (
    <Wrapper
      className={
        product.in_stock > 0 ? "product-card" : "product-card disabled"
      }
      onClick={() => {
        product.in_stock > 0
          ? localStorage.setItem("modalProduct", JSON.stringify(product))
          : console.log();
      }}
    >
      {basket?.items?.filter((item) => item.item_id === product._id)[0]
        ?.discount > 0 && (
        <span className="fa-stack fa-lg">
          <i className="fa fa-tag fa-stack-2x"></i>
          <i className="fa fa-stack-1x fa-inverse">
            {Math.round(
              basket?.items?.filter((item) => item.item_id === product._id)[0]
                ?.discount
            )}
            %
          </i>
        </span>
      )}
      <ProductImg className="product-img" loading="lazy">
        <LazyLoadImage
          onClick={product.in_stock > 0 ? onClick : console.log()}
          alt={product.name}
          src={product.representation}
          effect="blur"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = Broken;
          }}
        />
      </ProductImg>
      <div onClick={product.in_stock > 0 ? onClick : console.log()}>
        <ProductSize>
          {product.size > 0 && product.size} {t(product.size_type)}{" "}
          {!product.size && "-"}
        </ProductSize>
        <ProductPrice>
          от{" "}
          {basket?.items?.filter((item) => item.item_id === product._id)[0]
            ? basket?.items
                ?.filter((item) => item.item_id === product._id)[0]
                ?.price?.toLocaleString()
            : product?.prices[0]?.price?.toLocaleString()}
        </ProductPrice>
        <ProductTitle>
          {/* {lang === "ru" ? product.names.ru : product.names.en} */}
          {product.name}
        </ProductTitle>
      </div>
      <AddBasket product={product} />
    </Wrapper>
  );
});

export default Product;
