import { useEffect, useState } from "react";
import axios from "axios";
import Product from "../../main/product/product";
import { Wrapper } from "./similar-products.e";
import { Grid4 } from "../global/global";

function SimilarProducts({ category_id, onClick }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        "https://beeto.in1.uz/api/item/get",
        { category_id: `${category_id}` },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        if (window.innerWidth > 2100) {
          setData(res.data.data[0].items.slice(0, 10));
        } else {
          setData(res.data.data[0].items.slice(0, 8));
        }
        setLoading(false);
      });
  }, [category_id]);

  return (
    <>
      {loading && <div className="loading" />}
      <Wrapper
        className="similar"
        style={loading ? { opacity: 0 } : { opacity: 1 }}
      >
        <h3>Похожие продукты</h3>
        <Grid4 className="grid">
          {data &&
            data.map((product, index) => {
              return (
                <Product onClick={onClick} key={index} product={product} />
              );
            })}
        </Grid4>
      </Wrapper>
    </>
  );
}

export default SimilarProducts;
