import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --main: ${(props) => (props.isLight ? "#091B3D" : "#fefbf4")};
    --isabelline:  ${(props) => (props.isLight ? "#F4F1EB " : "#131519")};
    --header: ${(props) => (props.isLight ? "#fefbf4 " : "#0d0f11")};
    --border-color: ${(props) => (props.isLight ? "#f4f4f4 " : "#272B30")};
    --body-color: ${(props) => (props.isLight ? "#fff " : "#131519")};
    --orange: #FFAA00;
    --dropdown: ${(props) => (props.isLight ? "#fff " : "#111315")};
    --main-dark: ${(props) => (props.isLight ? "#151A24 " : "#fefbf4")};
    --white: #fff;
    --body: ${(props) => (props.isLight ? "#fff " : "#131519")};
    --yellow-bg: ${(props) => (props.isLight ? "#fff5e0 " : "rgba(0,0,0,0.3)")};
    --secondary-text: ${(props) => (props.isLight ? "#ADAAA6 " : "#ADAAA6")};
    --slider-one: ${(props) => (props.isLight ? "#b8d5f1 " : "#4f5461")};
    --slider-two: ${(props) => (props.isLight ? "#efb845 " : "#928979")};
    --slider-three: ${(props) => (props.isLight ? "#7fdddd " : "#282c35")};
    --icon:${(props) => (props.isLight ? "#9DA4B1" : "#6F767E")};
    --product-card: ${(props) => (props.isLight ? "#FCFCFC" : "#111315")};
    --product-img: ${(props) => (props.isLight ? "#fff" : "#1A1D1F")};
    --toggle: ${(props) => (props.isLight ? "#f4f4f4" : "#111315")};
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--orange); 
    border-radius: 24px;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    caret-color: var(--orange);
    color: var(--main);
    font-family: 'Open Sans', sans-serif;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    @media (min-width: 801px) {
      overflow-y: scroll !important;
      padding-right: 0 !important;
    }
    &.no-scrool {
      overflow: hidden;
    }
    background: var(--body-color);
    transition: 0.1s all ease-in-out;
  }
  input {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    padding: 13px 0;
    width: 100%;
    margin: 0 10px;
    color: var(--main);
    font-weight: 500;
    ::placeholder {
      color: #9da4b1;
    }
    @media (min-width: 1500px) {
      padding: 11px 0;
    }
  }
  button {
    cursor: pointer;
    border: none;
    outline: none;
    font-weight: 500;
  }
  fieldset {
    border: none !important;
    outline: none !important;
  }
  ul, li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
      color: var(--orange);
      cursor: pointer;
    }
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 24px;
    color: var(--main);
    @media (max-width: 800px) {
      margin-top: 15px;
    }
  }
  .scrollspy {
    div {
      width: 100vw;
      margin: 0 auto;
      display: flex;
      overflow-x: auto;
      position: fixed;
      padding: 0 18px;
      top: 72px;
      padding-top: 8px;
      padding-bottom: 12px;
      left: 0;
      z-index: 11;
      background-color: var(--body-color);
      box-shadow: 0 4px 12px rgb(0 0 0 / 7%);
    }
    a {
      color: var(--icon);
      flex: 0 0 auto;
      margin-right: 6px;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 30px;
      display: inline-block;
    }
    div::-webkit-scrollbar {
      display: none;
    }
    a.isCurrent {
      background-color: var(--orange);
      color: #fff !important;
    }
  }
  .MuiList-padding {
    padding: 0 !important;
  }
  .MuiMenuItem-root {
    padding: 14px 16px !important;
    bottom: 0.5px solid var(--border-color) !important;
  }
  .MuiList-root {
    background: var(--dropdown) !important;
  }
  .MuiPaper-root {
    border-radius: 18px !important;
    margin-top: 10px !important;
    border: none;
  }
  .loading {
    animation: spin 0.5s infinite linear;
    border: 2px solid var(--orange);
    border-left: 4px solid var(--dropdown);
    border-radius: 50%;
    height: 35px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 35px;
    margin: 30px auto;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .small-loader {
    animation: spin 0.5s infinite linear;
    border: 2px solid #fff;
    border-left: 3px solid var(--orange);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .MuiPagination-root {
    margin: 40px 0;
    .MuiPagination-ul {
      justify-content: center;
      button {
        padding: 10px;
        margin: 0 4px;
        border: 1px solid var(--orange);
        font-weight: 500;
        color: var(--main);
        &.Mui-selected {
          color: var(--orange);
          background-color: var(--isabelline);
        }
      }
    }
  }
  .Toastify__toast-container {
    top: 6rem !important;
  }
  .MuiButtonBase-root {
    display: none !important;
  }
  .error {
    color: #b00020;
    margin-top: 10px;
    span {
      color: #b00020;
      margin-right: 6px;
    }
  }
  .success {
    margin-top: 10px;
    color: #5cb85c;
    span {
      color: #5cb85c;
      margin-right: 6px;
    }
  }
  @media (max-width: 800px) {
    #chatra {
      bottom: 105px !important;
    }
    .loading {
      margin-top: 100%;
    }
    #text {
      display: none !important;
    }
  }
`;

export const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-top: 36px;
  &.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h2 {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 24px;
  }
  h5 {
    font-weight: 600;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 18px;
  }
  @media (max-width: 1250px) {
    width: 90%;
  }
  @media (mix-width: 2000px) {
    width: 1500px;
  }
  @media (min-width: 1550px) {
    width: 1500px;
  }
  @media (min-width: 1650px) {
    width: 1500px;
    padding: 4px 0;
  }
  @media (min-width: 2000px) {
    max-width: 1500px;
  }
  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
    padding: 0 12px;
    padding-bottom: 90px;
    h2 {
      font-size: 24px;
    }
  }
`;

export const CategoryItem = styled.div`
  padding-bottom: 30px;
  padding-top: 10px;
  h3 {
    font-size: 22px;
    margin-bottom: 18px;
    color: var(--main);
  }
  @media (max-width: 800px) {
    padding-bottom: 0;
    h3 {
      font-size: 22px !important;
      padding-top: 80px;
    }
    &.first {
      h3 {
        padding-top: 0 !important;
      }
      padding-top: 140px;
    }
    .empty {
      h3 {
        margin-top: 0;
      }
    }
  }
`;

export const Grid1x3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 32px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    margin: 0;
  }
  @media (min-width: 1550px) {
    grid-gap: 48px;
    grid-template-columns: 1.5fr 3.6fr 320px;
  }
`;

export const Grid3x2 = styled.div`
  display: grid;
  grid-template-columns: 60% 32%;
  justify-content: space-between;
  grid-gap: 40px;
  h2 {
    font-weight: 700;
    font-size: 36px;
    color: var(--main);
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    margin: 18px 0;
    h2 {
      font-size: 28px;
    }
  }
`;

export const Grid3 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
  @media (min-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 18px;
  }
`;

export const Grid4 = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
  @media (min-width: 1501px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    grid-gap: 12px;
    overflow: hidden;
  }
`;

export const Grid5 = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 12px;
  .product-img {
    height: 182px;
  }
  @media (min-width: 1500px) {
    grid-template-columns: repeat(7, 1fr);
    .product-img {
      height: 142px;
    }
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
    .product-img {
      height: 140px;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.forCart ? "12px 30px" : "14.5px 17px")};
  background-color: var(--isabelline);
  border-radius: 12px;
  font-size: 14px;
  margin: 0 9px;
  font-weight: 600;
  cursor: pointer;
  .shopping {
    margin-right: 10px;
    font-size: 20px;
    stroke: #fff;
    transform: translateY(3px);
  }
  &.yellow {
    background-color: var(--orange);
    color: var(--white);
    padding: 13px 17px;
    i {
      color: var(--white);
      font-size: 16px;
    }
  }
  &.basket {
    display: block !important;
  }
  i {
    transform: translateY(1.6px);
    font-size: 16px;
  }
  @media (min-width: 1550px) {
    &.basket {
      display: none !important;
    }
    padding: ${(props) => (props.forCart ? "14px 30px" : "16px 17px")};
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
