import styled from "styled-components";
import Modal from "@mui/material/Modal";

export const Wrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-6px);
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
  // dicsount badge
  .fa-stack {
    position: absolute;
    top: 135px;
    right: 0px;
    transform: rotate(-15deg);
    font-size: 21px;
    z-index: 11;
    .fa-tag {
      color: #cd1a3a;
    }
    .fa-inverse {
      transform: rotate(45deg);
      font-size: 12px;
      font-weight: 600;
    }
    @media (max-width: 800px) {
      top: 90px;
      right: 0;
      font-size: 18px;
      .fa-inverse {
        font-size: 10px;
      }
    }
  }
  @media (max-width: 800px) {
    cursor: auto;
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`;

export const ProductImg = styled.div`
  height: 170px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
  @media (max-width: 1200px) {
    min-height: 90px;
  }
  @media (max-width: 600px) {
    padding: 0;
    height: 120px;
  }
`;

export const ProductSize = styled.p`
  font-size: 14px;
  color: var(--main);
  opacity: 0.5;
  @media (max-width: 800px) {
    padding-top: 10px;
    font-size: 12px;
  }
`;

export const ProductTitle = styled.h4`
  margin: 8px 0 10px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 600px) {
    height: 62px;
    font-size: 13px;
    margin-top: 6px;
  }
`;

export const ProductPrice = styled.p`
  margin: 8px 0;
  font-size: 18px;
  font-weight: 700;
  color: var(--orange);
  @media (max-width: 600px) {
    margin: 4px 0;
    font-size: 15px;
  }
`;

export const AddButton = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  border: 2px solid var(--border-color);
  background-color: var(--product-card);
  border-radius: 15px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  &.product-card {
    padding: 0;
    button {
      padding: 13px;
      font-size: 16px;
    }
  }
  * {
    fill: var(--main) !important;
    color: var(--main) !important;
  }
  &.yellow {
    background-color: var(--orange);
    color: #fff;

    justify-content: space-around !important;
    * {
      color: #fff !important;
      fill: #fff !important;
    }
    .small-loader {
      margin: 0 auto;
      width: 20px;
      height: 20px;
    }
  }
  button {
    font-size: 13px;
    color: var(--main-dark);
    border: none;
    font-weight: 600;
    width: 40%;
    background-color: transparent;
    transition: 0.3s background ease-in-out;
    width: 100%;
    &:active {
      @media (min-width: 801px) {
        &.left {
          background: linear-gradient(90deg, #eee, transparent);
        }
        &.right {
          background: linear-gradient(-90deg, #eee, transparent);
        }
      }
    }
    &:disabled {
      background-color: var(--border-color);
      cursor: not-allowed;
      opacity: 0.8;
      color: var(--icon);
      border-radius: 10px;
    }
    svg {
      fill: var(--main-dark);
    }
  }
  span {
    transition: 0.15s transform ease-in-out;
    font-weight: 600;
    @media (max-width: 800px) {
      font-size: 15px;
    }
  }
  svg {
    fill: var(--main);
    transform: translateY(2px);
    @media (max-width: 800px) {
      font-size: 15px;
    }
  }
  .small-loader {
    height: 10px;
  }
  &:active span {
    @media (min-width: 801px) {
      transform: scale(1.2);
    }
  }
  @media (max-width: 800px) {
    cursor: auto;
    height: 45px;
    &.product-card {
      padding: 0;
      button {
        font-size: 14px !important;
      }
    }
    button.not_in_stock {
      font-size: 11px !important;
    }
  }
`;

export const AsModal = styled(Modal)`
  z-index: 98 !important;
  outline: none !important;
  .MuiBox-root {
    outline: none !important;
    width: 48%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 18px;
    background-color: var(--product-card);
    box-shadow: 0px 4px 36px rgba(21, 26, 36, 0.08);
    border-radius: 12px;
    height: 91vh;
    overflow-y: auto;
    @media (max-width: 1500px) {
      width: 60% !important;
      grid-template-columns: 1fr;
    }
    @media (max-width: 1200px) {
      width: 80% !important;
    }
    @media (max-width: 700px) {
      width: 100% !important;
      height: 100vh;
      padding-bottom: 60px;
      margin-top: 0px;
      top: 0;
      transform: translate(-50%);
      .MuiBox-root {
        grid-template-columns: 1fr;
      }
    }
  }
  @media (min-width: 801px) {
    .similar {
      padding: 18px 24px;
      border: 1.5px solid var(--border-color);
    }
  }
  @media (max-width: 801px) {
    .similar {
    }
  }
`;

export const ModalCard = styled.div`
  background-color: var(--body-color);
  border-radius: 18px;
  border: 2.5px solid var(--border-color);
  padding: 12px 24px;
  overflow-x: hidden;
  min-height: 190px;
  h3 {
    font-weight: 600;
    font-size: 18px;
    color: var(--main);
    margin-bottom: 0;
  }
  @media (max-width: 600px) {
    height: fit-content;
    min-height: fit-content;
    h3 {
      font-size: 20px;
    }
  }
`;

export const ModalHead = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border-color);
  position: relative;
  img {
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
  }
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1),
      inset 0px -1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -1px 1px rgba(0, 0, 0, 0.04),
      inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    border-radius: 27px;
    display: none;
  }
  @media (max-width: 800px) {
    border-bottom: none !important;
    .close {
      display: flex;
    }
    img {
      width: 60%;
      margin-top: 0;
      margin: 0 auto;
      display: block;
      border-radius: 16px;
    }
  }
`;

export const ModalRight = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  grid-template-rows: 0.5fr 1fr auto;
  .prices {
    div {
      display: flex;
      gap: 20px;
      font-weight: 600;
    }
    div:nth-child(1) {
      font-size: 16px;
      margin-bottom: 8px;
    }
    div:nth-child(2) {
      font-size: 18px;
      margin-bottom: 6px;
    }
    div:nth-child(3) {
      font-size: 20px;
    }
  }
  .prices-container {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
  }
  @media (max-width: 800px) {
    grid-template-rows: auto;
    grid-gap: 15px;
  }
`;

export const ModalSection = styled.div`
  margin: 10px 0;
  position: relative;
  width: 100%;
  &.flex {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 17px;
    }
  }
  h5 {
    width: 80%;
    font-weight: 600;
    font-size: 17px;
    color: var(--main);
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;
    line-height: 1.5;
  }
  i {
    position: absolute;
    top: 4px;
    right: 0px;
    color: var(--orange);
    cursor: pointer;
    font-size: 24px;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    margin-top: 4px;
    line-height: 21px;
    color: var(--main);
  }
  span {
    margin-top: 6px;
    font-weight: 500;
    font-size: 13px;
    color: #9e9b98;
  }
  @media (max-width: 600px) {
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 18px;
    }
    span {
      font-size: 17px;
    }
    i {
      font-size: 24px;
    }
  }
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 12px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
    margin-bottom: 50px;
  }
`;
