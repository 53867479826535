import React, { useContext, useEffect } from "react";
import ym, { YMInitializer } from "react-yandex-metrika";
import { useHistory } from "react-router-dom";
import ProductModal from "./components/main/products/modal";
import LoginRegister from "./components/common/login-register/login-register";
import Add from "./components/add/add";
import Routes from "./routes/routes";
import { GlobalStyle } from "./components/common/global/global";
import { ProductModalContext } from "./context/productModal";
import { DarkModeContext } from "./context/darkMode";
import { AuthContext } from "./context/auth";
import useBasket from "./hooks/useBasket";

const App = () => {
  const { darkMode } = useContext(DarkModeContext);
  const { isLogged } = useContext(AuthContext);
  const { productModal, setProductModal } = useContext(ProductModalContext);
  const { getBasket } = useBasket();
  const history = useHistory();

  history.listen(() => {
    ym("hit", `/`);
  });

  useEffect(() => {
    if (isLogged) {
      getBasket();
    }
  }, [isLogged]);

  if (localStorage.getItem("loggedDate")) {
    if (
      Number(
        -1 *
          (new Date().getDay() * 24 +
            new Date().getHours() -
            localStorage.getItem("loggedDate")) >
          23
      )
    ) {
      localStorage.setItem("userData", "");
      localStorage.removeItem("loggedDate");
      localStorage.removeItem("token");
      localStorage.removeItem("basket_id");
    }
  }

  return (
    <>
      <React.Suspense fallback={<div className="loading" />}>
        <YMInitializer
          accounts={[88688696]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            trackHash: true,
          }}
          version="2"
        />
        <Add />
        <Routes />
        <GlobalStyle isLight={darkMode} />
        {productModal && (
          <ProductModal
            open={productModal}
            setClose={() => setProductModal(false)}
          />
        )}
        <LoginRegister />
      </React.Suspense>
    </>
  );
};

export default App;
