import { useContext, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import axios from "axios";
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import { HiOutlinePhone } from "react-icons/hi";
import { AuthContext } from "../../../context/auth";
import Button from "../button/button";
import Modal from "../modal/modal";
import { Phone, Wrapper } from "./login-register.e";
import { LoginModalContext } from "../../../context/loginModal";

const LoginRegister = () => {
  const { setIsLogged } = useContext(AuthContext);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [stepLogin, setStepLogin] = useState(0);
  const [loading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  const { t } = useTranslation();
  const { loginModal, setLoginModal } = useContext(LoginModalContext);

  function clearData() {
    localStorage.setItem("userData", "");
    localStorage.removeItem("loggedDate");
    localStorage.removeItem("token");
    localStorage.removeItem("basket_id");
    setIsLogged(false);
    window.location.reload();
  }

  useEffect(() => {
    if (localStorage.getItem("loggedDate")) {
      new Date().getDay() * 24 +
        new Date().getHours() -
        localStorage.getItem("loggedDate") >
        23 && clearData();
    }
  }, []);

  useEffect(() => {
    if (stepLogin === 1) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  const nextStep = () => {
    if (phoneNumber.replace(/[^\d]/g, "").length !== 9) {
      setError(t("not_format"));
    } else {
      setError("");
      setLoading(true);
      axios
        .post(
          `https://beeto.in1.uz/api/client/login`,
          { phone_number: `+998${phoneNumber.replace(/[^\d]/g, "")}` },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response) => {
          setLoading(false);
          setStepLogin(1);
        });
    }
  };

  const handleLogin = () => {
    setLoading(true);
    axios
      .post(
        `https://beeto.in1.uz/api/client/verify`,
        {
          phone_number: `+998${phoneNumber.replace(/[^\d]/g, "")}`,
          otp: `${otp}`,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("token", JSON.stringify(response.data.data.token));
        localStorage.setItem(
          "loggedDate",
          new Date().getDay() * 24 + new Date().getHours()
        );
        setIsLogged(true);
        setStepLogin(0);
        setLoginModal(false);
        setOtp("");
        setPhoneNumber("");
      })
      .catch(() => {
        alert("Noto'g'ri");
        setLoading(false);
        setOtp("");
      });
  };

  return (
    loginModal && (
      <Modal
        width={window.innerWidth > 800 ? "460px" : "400px"}
        setClose={setLoginModal}
        open={loginModal}
        disable={stepLogin === 1}
        otherClose={() => {
          setStepLogin(0);
          setPhoneNumber();
        }}
      >
        {!stepLogin ? (
          <Wrapper>
            <h2>{t("login")}</h2>
            <p>{t("send_code")}</p>
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                nextStep();
              }}
            >
              <Phone>
                <span>
                  <HiOutlinePhone />
                  +998
                </span>
                <InputMask
                  mask="(99) 999-99-99"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                >
                  {(inputProps) => (
                    <input
                      {...inputProps}
                      placeholder="-- --- -- --"
                      type="tel"
                    />
                  )}
                </InputMask>
              </Phone>
              {error && <p className="error">{error}</p>}
            </form>
            <Button
              full
              loading={loading}
              color="yellow"
              text={t("continue")}
              onClick={nextStep}
            />
            <span className="bottom">{t("hit_login")}</span>
          </Wrapper>
        ) : (
          <Wrapper>
            <p style={{ marginTop: "0" }}>
              +998{phoneNumber} {t("otp_code")}
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <OtpInput
                value={otp}
                onChange={(otp) => setOtp(otp)}
                numInputs={4}
                className="otp"
                type="number"
                isInputNum
              />
              <Button
                full
                loading={loading}
                color="yellow"
                text={t("continue")}
                onClick={handleLogin}
              />
            </form>
            <div className="small">
              {seconds === 0 ? (
                <p
                  onClick={() => {
                    nextStep();
                    setSeconds(59);
                  }}
                >
                  {t("otp_resend")}
                </p>
              ) : (
                <p>
                  Kodni{" "}
                  <span>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </span>{" "}
                  {t("time_resend")}
                </p>
              )}
            </div>
          </Wrapper>
        )}
      </Modal>
    )
  );
};

export default LoginRegister;
