import { useEffect, useState, useContext } from "react";
import axios from "axios";
import useLogout from "./useLogout";
import { BasketContext } from "../context/basket";

export default function useBasket() {
  const [basketLoading, setBasketLoading] = useState(false);
  const { logoutByStatus } = useLogout();
  const { setBasket, setTotalPrice } = useContext(BasketContext);

  const updateBasket = (products) => {
    setBasketLoading(true);
    const basket = [];
    localStorage.getItem("basket_id") &&
      axios
        .post(
          `https://beeto.in1.uz/api/basket/update`,
          {
            _id: localStorage.getItem("basket_id"),
            items: products ? products : basket,
          },
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("token")
              )}`,
            },
          }
        )
        .then((response) => {
          setTotalPrice(
            response.data.data.total ? response.data.data.total : 0
          );
          setBasket(response.data.data ? response.data.data : []);
          setBasketLoading(false);
        })
        .catch((error) => {
          logoutByStatus(error.response.status, error.response.statusText);
          setBasketLoading(false);
        });

    // !localStorage.getItem("basket_id") && createBasket(products);
  };

  const createBasket = (products) => {
    const basket = [];
    axios
      .post(
        `https://beeto.in1.uz/api/basket/create`,
        { items: products ? products : basket },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      )
      .then((response) => {
        localStorage.setItem("basket_id", response.data.data._id);
      })
      .catch((error) => {});
  };

  const getBasket = () => {
    axios
      .post(
        `https://beeto.in1.uz/api/basket/get`,
        {},
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      )
      .then((response) => {
        setBasket(response.data.data ? response.data.data : []);
        setTotalPrice(response.data.data.total ? response.data.data.total : 0);
        localStorage.setItem("basket_id", response.data.data._id);
      })
      .catch((error) => {
        createBasket();
        error.response &&
          logoutByStatus(error.response.status, error.response.statusText);
      });
  };

  useEffect(() => {
    // axios
    //   .get("https://beeto.in1.uz/api/setting")
    //   .then((res) => {
    //   })
    //   .catch((e) => {
    //     if (axios.isCancel(e)) return;
    //   });
    // return;
  }, []);

  return { getBasket, updateBasket, createBasket, basketLoading };
}
