import { createContext, useState } from "react";

export const LoginModalContext = createContext();

export function LoginProvider(props) {
  const [loginModal, setLoginModal] = useState(false);

  return (
    <LoginModalContext.Provider value={{ loginModal, setLoginModal }}>
      {props.children}
    </LoginModalContext.Provider>
  );
}
