import { useContext } from "react";
import { AuthContext } from "../context/auth";

const useLogout = () => {
  const { setIsLogged } = useContext(AuthContext);

  const logoutFunc = () => {
    localStorage.setItem("userData", "");
    localStorage.removeItem("loggedDate");
    localStorage.removeItem("token");
    localStorage.removeItem("basket_id");
    setIsLogged(false);
  };

  const logoutByStatus = (status, errorMessage, additional) => {
    if (status === 401) {
      localStorage.setItem("userData", "");
      localStorage.removeItem("loggedDate");
      localStorage.removeItem("token");
      localStorage.removeItem("basket_id");
      setIsLogged(false);
    } else {
      additional && additional();
      alert(errorMessage);
    }
  };

  return { logoutFunc, logoutByStatus };
};

export default useLogout;
