import { useEffect, useState } from "react";
import axios from "axios";
import { HiOutlineX } from "react-icons/hi";
import { Wrapper } from "./add.e";

function Add() {
  const [show, setShow] = useState(
    JSON.parse(localStorage.getItem("add")) === false
      ? JSON.parse(localStorage.getItem("add"))
      : true
  );
  const [ad, setAd] = useState();

  useEffect(() => {
    axios
      .post(
        "https://beeto.in1.uz/api/news/get-pagin",
        { limit: 20, page: 1 },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setAd(res.data.data.data[0]);
      });
  }, []);

  return (
    show && (
      <Wrapper>
        {ad && (
          <>
            <img rel="preload" src={ad.image} alt="" />
            <div
              onClick={() => {
                localStorage.setItem("add", false);
                setShow(false);
              }}
            >
              <HiOutlineX />
            </div>
          </>
        )}
      </Wrapper>
    )
  );
}

export default Add;
